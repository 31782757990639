import React  from "react"
export const Finance = ({className}) => {
    return( 
        <svg className={className} fill="currentColor"  xmlns="http://www.w3.org/2000/svg"  
                    viewBox="0 0 136.06 136.06" >
                    <path d="M123.829,50.635c-0.371-1.782-1.411-3.311-2.918-4.309l-1.062-0.7c0.457-1.549,0.521-3.215,0.085-4.881
                    c-0.647-2.494-2.239-4.68-4.488-6.166c-4.511-2.993-10.898-1.963-13.637,2.175L86.348,60.122c-0.52,0.785-0.286,1.836,0.499,2.355
                    L73.741,82.312c-0.011,0-0.011,0-0.011,0c-0.074,0.106-0.085,0.223-0.138,0.34c-0.032,0.106-0.096,0.19-0.106,0.297
                    c0,0.011-0.011,0.011-0.011,0.021l-2.759,16.48c-0.106,0.658,0.18,1.326,0.742,1.697c0.266,0.181,0.595,0.276,0.935,0.276
                    c0.318,0,0.637-0.085,0.912-0.266l14.093-8.999c0,0,0.011-0.01,0.011-0.021c0.096-0.054,0.17-0.148,0.244-0.213
                    c0.085-0.085,0.181-0.159,0.233-0.244l0.011-0.01l13.105-19.834l0,0c0.276,0.19,0.595,0.286,0.934,0.286
                    c0.117,0,0.234-0.021,0.34-0.031c0.436-0.096,0.828-0.361,1.083-0.732l14.963-22.667l0.711,0.478
                    c0.753,0.487,1.273,1.252,1.464,2.144c0.191,0.891,0,1.804-0.488,2.557l-8.712,13.181c-1.157-0.053-2.313,0.425-3.003,1.464
                    c-1.029,1.561-0.605,3.672,0.965,4.701c1.55,1.029,3.661,0.605,4.701-0.955c0.69-1.04,0.669-2.292,0.17-3.343l8.713-13.169
                    C123.84,54.232,124.19,52.418,123.829,50.635z M75.078,93.975l1.315-7.874l6.973,4.605l-6.739,4.298L75.078,93.975z M85.998,88.382
                    l-8.49-5.624l12.141-18.412l8.521,5.624L85.998,88.382z M115.966,46.114L101.459,68.07l0,0l-11.333-7.492l14.517-21.956
                    c1.73-2.642,5.89-3.215,8.946-1.209c1.528,1.019,2.61,2.504,3.057,4.181C117.069,43.217,116.815,44.819,115.966,46.114z"/>
            
                    <path d="M38.223,58.233h36.982c0.935,0,1.698-0.765,1.698-1.698c0-0.944-0.764-1.698-1.698-1.698H38.223
                        c-0.944,0-1.698,0.754-1.698,1.698C36.525,57.469,37.278,58.233,38.223,58.233z"/>
                    <path d="M75.205,68.421H38.223c-0.944,0-1.698,0.753-1.698,1.697c0,0.935,0.753,1.698,1.698,1.698h36.982
                        c0.935,0,1.698-0.764,1.698-1.698C76.903,69.174,76.14,68.421,75.205,68.421z"/>
                    <path d="M38.223,44.597h16.979c0.934,0,1.698-0.764,1.698-1.698c0-0.945-0.764-1.698-1.698-1.698H38.223
                        c-0.944,0-1.698,0.753-1.698,1.698C36.525,43.833,37.278,44.597,38.223,44.597z"/>
                    <path d="M36.525,83.702c0,0.923,0.753,1.697,1.698,1.697h29.682l0.478-2.865l0.011-0.53h-30.17
                        C37.278,82.004,36.525,82.758,36.525,83.702z"/>
                    <path d="M64.827,92.935l-12.479,18.719l-9.583-8.839c-0.679-0.627-1.762-0.584-2.388,0.095
                        c-0.647,0.69-0.594,1.772,0.095,2.409l11.037,10.188c0.308,0.286,0.722,0.445,1.146,0.445c0.064,0,0.127,0,0.202-0.01
                        c0.488-0.064,0.934-0.329,1.21-0.743L67.65,94.823c0.52-0.785,0.308-1.836-0.467-2.355C66.397,91.947,65.347,92.159,64.827,92.935
                        z"/>
                    <path d="M29.733,39.556c-1.878,0-3.396,1.518-3.396,3.396c0,1.878,1.518,3.396,3.396,3.396
                        c1.878,0,3.396-1.518,3.396-3.396C33.129,41.074,31.611,39.556,29.733,39.556z"/>
                    <path d="M29.733,53.14c-1.878,0-3.396,1.518-3.396,3.396s1.518,3.396,3.396,3.396c1.878,0,3.396-1.518,3.396-3.396
                        S31.611,53.14,29.733,53.14z"/>
                    <path d="M29.733,66.723c-1.878,0-3.396,1.518-3.396,3.396c0,1.879,1.518,3.396,3.396,3.396
                        c1.878,0,3.396-1.518,3.396-3.396C33.129,68.24,31.611,66.723,29.733,66.723z"/>
                    <path d="M29.733,80.306c-1.878,0-3.396,1.518-3.396,3.396c0,1.878,1.518,3.396,3.396,3.396
                        c1.878,0,3.396-1.518,3.396-3.396C33.129,81.823,31.611,80.306,29.733,80.306z"/>
            
                <path d="M86.613,98.707v17.244c0,2.813-2.261,5.094-5.052,5.094H23.748c-2.791,0-5.051-2.281-5.051-5.094V24.275
                    c0-2.813,2.26-5.094,5.051-5.094h37.556V36.16c0,4.68,3.789,8.489,8.447,8.489h16.862v5.858l3.396-5.147v-2.409
                    c0-0.445-0.18-0.881-0.488-1.199L64.211,16.284c-0.318-0.318-0.753-0.499-1.21-0.499H23.748c-4.659,0-8.447,3.81-8.447,8.49v91.676
                    c0,4.681,3.789,8.49,8.447,8.49h57.813c4.659,0,8.447-3.81,8.447-8.49V96.553l-0.095,0.043L86.613,98.707z M64.7,21.601
                    l19.526,19.653H69.751c-2.791,0-5.051-2.282-5.051-5.094V21.601z"/>
            
            </svg>

    )
}