import React  from "react"
export const Card = ({className}) => {
    return( 
        <svg className={className} fill="currentColor"  xmlns="http://www.w3.org/2000/svg"  
        viewBox="57.53 57.539 20 19.981" >
    <g id="business_card_1_">
	<g>
		<g>
			<g>
				<path  d="M65.954,64.179l-2.924-2.235c-0.211-0.151-0.539-0.176-0.787,0.006l-2.92,2.229
					c-0.076,0.06-0.121,0.152-0.121,0.25l0.004,0.844c0,0.172,0.141,0.311,0.313,0.312h0.203l-0.002,2.528
					c0,0.265,0.217,0.48,0.48,0.48h4.877c0.266,0,0.482-0.216,0.482-0.48l-0.002-2.528h0.207c0.172-0.001,0.313-0.141,0.313-0.313
					v-0.844C66.077,64.331,66.032,64.239,65.954,64.179z M65.452,64.96h-0.207c-0.172,0.001-0.313,0.14-0.313,0.313l0.002,2.696
					h-4.59l0.002-2.694c0-0.173-0.141-0.313-0.313-0.313h-0.205l-0.002-0.377l2.828-2.138l2.797,2.137V64.96z"/>
				<path  d="M61.573,65.443v1.132c0,0.276,0.223,0.501,0.5,0.501h1.133c0.277,0,0.5-0.225,0.5-0.501v-1.132
					c0-0.276-0.223-0.502-0.5-0.502h-1.133C61.796,64.941,61.573,65.167,61.573,65.443z M62.198,65.566h0.883v0.886h-0.883V65.566z"
					/>
			</g>
			<path  d="M72.927,62.056h-5.322c-0.172,0-0.311,0.14-0.311,0.313v1.418c0,0.173,0.139,0.313,0.311,0.313h5.322
				c0.174,0,0.313-0.14,0.313-0.313v-1.418C73.239,62.196,73.1,62.056,72.927,62.056z M72.614,63.474h-4.697v-0.793h4.697V63.474z"
				/>
			<path  d="M72.927,64.894h-5.322c-0.172,0-0.311,0.141-0.311,0.313c0,0.173,0.139,0.313,0.311,0.313h5.322
				c0.174,0,0.313-0.14,0.313-0.313C73.239,65.035,73.1,64.894,72.927,64.894z"/>
			<path  d="M70.089,66.316h-2.484c-0.172,0-0.311,0.14-0.311,0.313s0.139,0.313,0.311,0.313h2.484
				c0.172,0,0.313-0.14,0.313-0.313S70.26,66.316,70.089,66.316z"/>
		</g>
		<path  d="M73.635,59.726H59.093c-0.861,0-1.563,0.7-1.563,1.563v9.061c0,0.86,0.701,1.562,1.563,1.562h3.426
			c-0.002-0.019-0.01-0.035-0.012-0.054c-0.02-0.194-0.006-0.385,0.023-0.571h-3.438c-0.518,0-0.938-0.421-0.938-0.937v-9.061
			c0-0.518,0.42-0.938,0.938-0.938h14.543c0.516,0,0.938,0.421,0.938,0.938v7.813l0.625,0.125v-7.938
			C75.198,60.427,74.499,59.726,73.635,59.726z"/>
	</g>
	<g>
		<g>
			<path  d="M74.969,73.515c-1.375,0-3.785-0.781-3.887-0.813c-0.963-0.252-1.553-1.258-1.299-2.235
				c0.25-0.961,1.273-1.568,2.242-1.318l3.398,0.887c0.176-0.078,0.309-0.926,0.234-1.857c-0.012-0.137,0.066-0.265,0.195-0.315
				c0.129-0.052,0.273-0.013,0.359,0.096c0.055,0.066,1.316,1.662,1.316,3.002c0,0.715-0.256,1.989-1.963,2.485
				C75.405,73.493,75.21,73.515,74.969,73.515z M71.563,69.715c-0.553,0-1.035,0.373-1.174,0.906
				c-0.168,0.647,0.223,1.312,0.869,1.481c0.041,0.013,2.436,0.788,3.711,0.788c0.178,0,0.32-0.015,0.424-0.046
				c1.004-0.291,1.512-0.926,1.512-1.885c0-0.581-0.313-1.256-0.617-1.774c-0.031,0.483-0.129,1-0.373,1.266
				c-0.129,0.138-0.291,0.21-0.471,0.21l0,0c-0.059,0-0.117-0.008-0.178-0.023l-3.396-0.886
				C71.768,69.726,71.667,69.715,71.563,69.715z"/>
		</g>
		<path  d="M74.044,74.38c-0.359,0.507-1.049,0.685-1.627,0.376l-5.771-2.678c-0.295-0.157-0.514-0.421-0.611-0.743
			c-0.098-0.321-0.063-0.661,0.094-0.954c0.328-0.611,1.09-0.842,1.719-0.512l1.014,0.471c0.008-0.035,0.008-0.072,0.018-0.107
			c0.043-0.172,0.105-0.334,0.18-0.49l-0.934-0.431c-0.895-0.477-2-0.151-2.508,0.716l-0.473-0.08
			c-0.605-0.1-1.215,0.131-1.598,0.604c-0.445,0.551-0.484,1.296-0.156,1.887c-0.27,0.223-0.459,0.567-0.504,0.951
			c-0.051,0.438,0.08,0.856,0.367,1.181c0.016,0.018,0.031,0.034,0.051,0.048l3.703,2.638c0.25,0.175,0.547,0.265,0.855,0.265
			c0.137,0,0.275-0.018,0.414-0.054c0.453-0.119,0.832-0.419,1.012-0.8c0.004-0.007,0.004-0.013,0.004-0.02
			c0.27,0.16,0.566,0.241,0.863,0.241c0.496,0,0.984-0.215,1.318-0.628c0.205-0.252,0.33-0.555,0.357-0.828
			c0.016-0.066,0.027-0.151,0.027-0.247l0.279,0.13c0.281,0.15,0.584,0.221,0.885,0.221c0.672,0,1.324-0.36,1.66-0.994
			c0.016-0.028,0.023-0.065,0.039-0.096C74.507,74.435,74.278,74.413,74.044,74.38z M68.725,76.398
			c-0.102,0.214-0.336,0.393-0.609,0.464c-0.158,0.042-0.465,0.081-0.746-0.117l-3.672-2.614c-0.203-0.248-0.207-0.524-0.191-0.67
			c0.027-0.226,0.133-0.425,0.281-0.545c0.012,0.01,0.02,0.021,0.031,0.031l4.973,3.362C68.764,76.335,68.741,76.363,68.725,76.398z
			 M70.921,74.753c0.355,0.177,0.324,0.44,0.297,0.561c-0.023,0.201-0.102,0.395-0.23,0.554c-0.369,0.459-1.043,0.529-1.523,0.145
			l-5.273-3.567c-0.457-0.369-0.527-1.043-0.16-1.501c0.242-0.3,0.627-0.445,1.01-0.382l0.355,0.06
			c-0.057,0.295-0.049,0.6,0.041,0.896c0.146,0.482,0.471,0.877,0.93,1.121l4.551,2.111C70.919,74.75,70.921,74.752,70.921,74.753z"
			/>
		<path  d="M66.577,58.418c0.635-0.445,1.512-0.29,1.957,0.344l0.238,0.34h0.764l-0.49-0.698
			c-0.645-0.916-1.91-1.14-2.828-0.497c-0.412,0.29-0.688,0.715-0.801,1.195h0.65C66.163,58.829,66.335,58.588,66.577,58.418z"/>
	</g>
</g>
</svg>
    )
}